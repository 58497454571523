import {
    Children, isValidElement, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState,
    useImperativeHandle,
    ReactNode,
    Ref
} from 'react';
import {usePopper} from 'react-popper';
import {useBooleanState} from 'hooks/useBooleanState';
import {stopPropagation} from 'utils';
import styles from './Popup.module.scss';
import {Content, ContentProps, PopupRef, Trigger} from './Popup';
import {Placement} from '@popperjs/core';

const usePopup = ({children, ref, placement, onClose, onOpen}: {
    children: ReactNode, ref: Ref<PopupRef>, placement?: Placement, onClose?: () => void,
    onOpen?: () => void
}) => {
    const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(null);
    const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
    const [trigger, content] = useMemo(() => Children.toArray(children)
        .reduce<[ReactElement | undefined, ReactElement<ContentProps> | undefined]>((acc, el) => {
            if (!isValidElement(el)) return acc;
            if (el.type === Trigger) {
                acc[0] = el;
                return acc;
            }
            if (el.type === Content) {
                acc[1] = el;
                return acc;
            }
            return acc;
        }, [undefined, undefined]), [children]);

    const {styles: popperStyles, attributes} = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        placement,
    });

    const [isOpen, open, _close] = useBooleanState();

    const close = useCallback(() => {
        onClose?.();
        _close();
    }, [onClose, _close]);

    useImperativeHandle(ref, () => ({
        close,
    }));

/*     useEffect(() => {
        window.addEventListener('scroll', close, true);
        return () => {
            window.removeEventListener('scroll', close, true);
        };
    }, []); */

    const handleClick = useCallback((e: MouseEvent) => {
        stopPropagation(e);
        if (!isOpen) {
            onOpen?.();
        }
        const change = isOpen ? close : open;
        change();
    }, [open, isOpen]);

    const paddingStyles = useMemo(() => {
        const {noPadding} = content?.props || {};
        if (!noPadding) return undefined;
        if (typeof noPadding === 'boolean') {
            return {[styles.noPadding]: noPadding};
        }
        return {
            [styles.noLeftPadding]: noPadding.left,
            [styles.noRightPadding]: noPadding.right,
            [styles.noTopPadding]: noPadding.top,
            [styles.noBottomPadding]: noPadding.bottom,
        };
    }, [content?.props.noPadding]);

    return {
        trigger,
        isOpen,
        setPopperElement,
        popperStyles,
        attributes,
        paddingStyles,
        content,
        handleClick,
        close,
        setReferenceElement
    }
}

export default usePopup