import React, { useCallback, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { HallSlotsQResponse } from '../../features/api/hallschema-api';
import { getSlotExtraOptions, getType, selectSlotByShift } from './utils';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { SCHEME_FACTOR } from '../hall-scheme/redux/HallSchemaV2/hall-schema';
import { constant } from 'lodash';
import { HallMode, useHallSchemaActions } from '../../features/HallSchema';
import {
  activeTablesSelector,
  hallModeSelector,
} from '../../features/HallSchema/selectors';
import {
  useSelectedTableId,
  useTableBookingListActions,
} from '../../features/TableBooking/slice';
import {
  moveBookingSelectors,
  useMoveBookingActions,
} from 'features/MoveBooking';
import moment from 'moment';

import { useSliderDatetime } from 'hooks/useSliderDatetime';
import { EDraggableEntity } from '../../constants';
import { Booking } from '../../types/booking';
import { getBookingStartTime, isManagerialTable } from '../../utils';
import { timelineSelectors, useTimelineActions } from '../../features/Timeline';
import { config } from '../../config';
import styles from './style.module.scss';
import { Notifications } from './components/Notifications/Notifications';
import { TableNumber } from './components/TableNumber/TableNumber';
import { TableBorder } from './components/TableBorder/TableBorder';
import { ICONS } from '../../ui-kit';
import { SvgForeignObjectBody } from './SvgForeignObject';
import { clientShortName } from 'common/helpers';
import { getStatusColor } from 'components/HallEditor/BookingTable/utils';

interface IIsCanDrop {
  ({
    booking,
    table,
  }: {
    booking?: Booking;
    table: HallSlotsQResponse['table'];
  }): boolean;
}

const isCanDrop: IIsCanDrop = ({ booking, table }) => {
  return !booking?.places.find((place) => place.id === table.table_id);
};

export const Table: React.FC<HallSlotsQResponse> = ({ table, slots }) => {
  const { setTime } = useTimelineActions();
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const { selectMoveSource, selectSourceTableNumber } = useMoveBookingActions();
  const { addTargetTables, removeTargetTable, selectTargetBooking }
    = useMoveBookingActions();

  const handleMoveClick = useCallback(
    (booking: Booking) => {
      const tableNumber = booking.places.map((place) => place.number);
      setTime(getBookingStartTime(booking));
      booking.bookingId && selectMoveSource(booking.bookingId);
      selectSourceTableNumber(tableNumber);
      const payload = {
        tableId: table.table_id,
        bookingId: booking?.bookingId,
        tableNumber: table.number.toString(),
      };
      addTargetTables(payload);
    },
    [table]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [EDraggableEntity.BOOKING_TABLE, EDraggableEntity.BOOKING_CARD],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        booking: monitor.getItem<Booking>(),
        canDrop: isCanDrop({ booking: monitor.getItem<Booking>(), table }),
      }),
      drop: handleMoveClick,
      canDrop: (booking) => {
        return isCanDrop({ booking, table });
      },
    }),
    [table, slots]
  );

  const timeWithDate = useSliderDatetime();
  const hallMode = useSelector(hallModeSelector);
  const activeTables = useSelector(activeTablesSelector);
  const { selectTableInHall, switchMode } = useHallSchemaActions();
  const {
    type,
    table_id,
    schema: { x, y, width, height, shape },
  } = table;
  const slot = selectSlotByShift(slots, timeWithDate());
  const {
    tableColor,
    timeWord,
    timeString,
    tableStatus,
    slot: { booking } = {},
  } = getSlotExtraOptions(slot, timeWithDate());
  const { setTable } = useTableBookingListActions();
  const selectedTableId = useSelector(useSelectedTableId);

  const checkBookingTime = useMemo(() => {
    if (!booking) return false;
    const timeOnLine = timeWithDate();
    const timeBooking = moment(
      `${booking?.bookingDate} ${booking?.bookingTime}`
    ).add(booking?.visitTime, 'minute');
    return timeOnLine <= timeBooking;
  }, [timeWithDate, booking]);

  const moveSource = useSelector(moveBookingSelectors.sourceBookingId);
  const isTableSelected = useMemo(
    () => moveBookingSelectors.isTableSelectedFactory(table.table_id),
    [table]
  );
  const isBookingSelected = useMemo(
    () =>
      booking
        ? moveBookingSelectors.isBookingSelectedFactory(booking.bookingId)
        : constant(false),
    [booking]
  );

  const isTableMoveTarget = useSelector(isTableSelected);
  const isBookingMoveTarget = useSelector(isBookingSelected);
  const isMoveTarget = useMemo(
    () => isTableMoveTarget || isBookingMoveTarget,
    [isTableMoveTarget, isBookingMoveTarget]
  );

  const moveBookingDisplayClass = () => {
    if (moveSource && moveSource === booking?.bookingId)
      return styles.moveSource;
    if (isMoveTarget) return styles.moveTarget;
    return undefined;
  };
  const modalType = getType(hallMode, booking?.bookingId);

  const isSelected = useSelector(isTableSelected);

  const tableColorClass
    = modalType === 'select-table'
    && !!activeTables.find((it) => it === table.table_id)
      ? cn(styles[tableColor], styles.selectedTable)
      : styles[tableColor];

  // todo: calculate classes selectedForMove
  const handleTableClick = (e: React.MouseEvent) => {
    // dont push this event higher to dom
    e.stopPropagation();
    if (modalType === 'swap-captured') {
      const payload = {
        tableId: table.table_id,
        bookingId: booking?.bookingId,
        tableNumber: table.number.toString(),
      };
      if (isSelected) {
        removeTargetTable(payload);
        return;
      }

      if (booking && isManagerialTable(booking)) {
        // @ts-ignore
        selectTargetBooking({ ...payload, bookingId: null });
      } else if (booking?.bookingId && checkBookingTime) {
        selectTargetBooking(payload);
      } else {
        addTargetTables(payload);
      }
      return;
    }

    if (modalType === 'select-table') {
      selectTableInHall(table_id);
      return;
    }

    setTable(table_id);

    if (hallMode !== HallMode.TABLE_BOOKINGS_LIST) {
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
      return;
    }
    // no handle if disable
    if (hallMode === HallMode.TABLE_BOOKINGS_LIST) {
      selectTableInHall(table_id);
    }
  };

  const surname
    = slot?.booking?.client?.surname || slot?.booking?.contact?.surname || null;
  const middle_name
    = slot?.booking?.client?.middle_name
    || slot?.booking?.contact?.middle_name
    || null;
  const name
    = slot?.booking?.client?.name || slot?.booking?.contact?.name || null;
  const fullname = useMemo(() => {
    if (!tableStatus) return null;
    return [surname, name]
      .filter((el) => el)
      .map((el, index) =>
        index === 0 ? el : `${el?.charAt(0).toUpperCase()}.`)
      .join(' ');
  }, [slot, tableStatus]);

  // @ts-ignore
  const [, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_TABLE,
      canDrag: config.dragAndDrop && !!fullname,
      item: slot?.booking,
    }),
    [fullname, slot]
  );

  const hasOverbooking = slots.some(({ booking: b }) => b?.isOverbooking);

  const isEndingSoon = useMemo(() => {
    if (!booking || tableColor !== 'green') return false;
    const timeOnLine = timeWithDate();
    const timeBooking = moment(
      `${booking?.bookingDate} ${booking?.bookingTime}`
    ).add(booking?.visitTime, 'minute');
    return timeBooking.diff(timeOnLine, 'minute') <= 20;
  }, [booking, timeWithDate, tableColor]);

  const statusColor
    = (booking
      && getStatusColor(booking, isActualTime, tableColor))
    || 'var(--floorplanTable_empty_background)';

  return (
    <g
      className={`table-${table.number}`}
      onClick={handleTableClick}
      ref={drop}
    >
      <g
        className={cn(
          styles.tableGroup,
          tableColorClass,
          moveBookingDisplayClass(),
          { [styles.selectedTable]: isOver }
        )}
      >
        {/* Рамка стола если бронь на несколько столов */}
        <foreignObject
          x={SCHEME_FACTOR * x - 15}
          y={SCHEME_FACTOR * y - 15}
          width={SCHEME_FACTOR * width + 30}
          height={SCHEME_FACTOR * height + 30}
        >
          <TableBorder
            isShow={Boolean(
              selectedTableId === table.table_id
                || (booking && booking.places.length > 1)
            )}
            shape={shape}
            statusColor={
              selectedTableId === table.table_id ? undefined : statusColor
            }
          />
        </foreignObject>

        {/* Стол */}
        <foreignObject
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y}
          width={SCHEME_FACTOR * width}
          height={SCHEME_FACTOR * height}
          className={cn(styles.tableWrapper, styles[shape])}
          style={{
            background: booking?.extraStatus
              ? `linear-gradient(to right top, ${statusColor} 50%, ${booking.extraStatus.color} 50%)`
              : statusColor,
          }}
        >
          <SvgForeignObjectBody
            as="div"
            className={cn(styles.tableBody)}
            aria-label="table-body"
            ref={drag}
          >
            <div className={cn(styles.tableContainer)}>
              <div className={styles.tableInfo}>
                <ICONS.GuestsIcon className={styles.guestIcon} />
                <span>{tableStatus ? booking?.persons : type}</span>
              </div>
              <div className={styles.bookingInfo}>
                {Boolean(timeString) && <span>{timeString}</span>}
              </div>
            </div>
          </SvgForeignObjectBody>
        </foreignObject>

        {/* Номер стола */}
        <foreignObject
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y - (20 + SCHEME_FACTOR) / 2}
          width={SCHEME_FACTOR * width}
          height={20 + SCHEME_FACTOR}
        >
          <TableNumber
            isBookingSoon={timeWord === 'BOOKING_SOON'}
            tableNumber={Number(table.number)}
            statusColor={
              selectedTableId === table.table_id ? undefined : statusColor
            }
          />
        </foreignObject>

        {/* Уведомления снизу стола */}
        <foreignObject
          x={SCHEME_FACTOR * x}
          y={
            SCHEME_FACTOR * y
            + height * SCHEME_FACTOR
            - (20 + SCHEME_FACTOR) / 2
          }
          width={SCHEME_FACTOR * width}
          height={20 + SCHEME_FACTOR}
        >
          <Notifications
            isOverbooking={hasOverbooking}
            isVip={Boolean(slot?.booking?.client?.vip)}
            isDeposit={Boolean(booking?.useDeposit)}
            isEndingSoon={isEndingSoon}
            isManagerial={Boolean(booking && isManagerialTable(booking))}
          />
        </foreignObject>

        {/* ФИО гостя */}
        <foreignObject
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y + SCHEME_FACTOR * height + 4}
          height={20}
          width={SCHEME_FACTOR * width}
        >
          <SvgForeignObjectBody as="div" className={styles.fullName}>
            <span ref={dragPreview}>{fullname}</span>
          </SvgForeignObjectBody>
        </foreignObject>
      </g>
    </g>
  );
};
