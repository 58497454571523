import { clientShortName } from 'common/helpers';
import type { Contact } from 'features/BookingFormProxy/types';
import type { Client } from 'models/client.model';
import moment from 'moment';
import type {
  Booking,
  BookingOrder,
  ManagerialClient,
  ManagerialTable,
} from 'types/booking';
import type { BookingTable } from 'types/table';
import { getBookingStartTime, isBooking, isManagerialTable } from 'utils';

export const getStatusColor = (
  booking: Booking | BookingOrder | ManagerialTable,
  isActualTime: boolean,
  tableColor: string
) => {
  if (booking.seatType === 'MANAGEMENT')
    return 'var(--status_constant_main_background)';
  if (isActualTime) {
    const diff = Math.max(
      moment().startOf('minute').diff(getBookingStartTime(booking), 'minute'),
      0
    );
    if (
      booking
      && isBooking(booking)
      && diff > 0
      && (booking.status?.category === 'PRE_SERVICE'
        || diff - booking.visitTime > 0)
    ) {
      return booking.status?.category === 'PRE_SERVICE' ? '#F7D694' : '#F7896F';
    }
    return (booking as Booking | undefined)?.status?.system_name === 'CONFIRMED' ? '#AAAAAA' 
    : (booking as Booking | undefined)?.status?.color ;
  }
  switch (tableColor) {
    case 'red':
      return '#F7896F';
    case 'yellow':
      return '#F7D694';
    case 'light_grey':
      return ((booking as Booking | undefined)?.status?.system_name === ('NEW') 
      || (booking as Booking | undefined)?.status?.system_name === ('CONFIRMED'))
        ? '#AAAAAA'
        : '#60C689';
    case 'green':
      return '#72E6F7';
    default:
      return (booking as Booking | undefined)?.status?.color;
  }
};

export const isCanDrag = (
  booking: Booking | undefined,
  tableId: BookingTable['tableId']
): boolean =>
  Boolean(
    booking?.bookingId && booking.places.some((place) => place.id === tableId)
  );