import React, {useCallback} from "react";
import {Form} from "react-final-form";

import {FormField} from "components/FormField";
import {useUpdatePasswordMutation} from "features/api/user-api";
import {Notification} from "services/notification";

import styles from "./NewPasswordForm.module.scss";
import {FormData, useLocalizedScheme} from "./utils";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {capitalizeString} from "../../utils";


interface NewPasswordFormProps{
  userId: number;
  onClose(): void;
}

export function NewPasswordForm({userId, onClose}: NewPasswordFormProps) {
  const {validate} = useLocalizedScheme();
  const {intl, getIntlJoinedParts, getIntlCurrentUnit, getIntlNewtUnit} = useIntlUtils();
  const [updatePassword] = useUpdatePasswordMutation();
  const handleUpdate = useCallback(async (data: Required<FormData>) => {
    try {
      await updatePassword({...data, userId}).unwrap();
      onClose();
    } catch (e) {
      Notification.error({title: e.data?.errorMessage});
      console.error(e);
    }
  }, [userId, onClose]);
  return (
    <Form onSubmit={handleUpdate} validate={validate}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormField name="old_password" type="password" label={getIntlCurrentUnit(ETranslations.AUTH_PASSWORD)} required />
          <FormField
            name="new_password"
            type="password"
            autoComplete="new-password"
            label={getIntlNewtUnit(ETranslations.AUTH_PASSWORD)}
            required
          />
          <FormField
            name="password_confirm"
            type="password"
            autoComplete="new-password"
            label={capitalizeString(getIntlJoinedParts([ETranslations.BASE_REPEAT,ETranslations.AUTH_PASSWORD]).toLowerCase())}
            required
          />
          <div className={styles.actions}>
            <button
              className="primary"
              type="submit">{capitalizeString(getIntlJoinedParts([ETranslations.BASE_CHANGE,ETranslations.AUTH_PASSWORD]).toLowerCase())}
            </button>
            <button className={styles.cancel} onClick={onClose} type="button">{intl.formatMessage({id: ETranslations.BASE_CANCEL})}</button>
          </div>
        </form>
      )}
    </Form>
  );
}
