import React, { useMemo } from 'react';
import cn from 'classnames';
import { TemplatePlaceholder } from '@devexpress/dx-react-core';
import { ICONS } from 'ui-kit';
import { TTableSlots } from '../../hall-scheme/redux/HallSchemaV2/NewScheme/utils';
import { useSliderDatetime } from 'hooks/useSliderDatetime';
import {
  getSlotExtraOptions,
  getType,
  selectSlotByShift,
} from '../../Table/utils';
import { useSelector } from 'react-redux';
import {
  activeTablesSelector,
  hallModeSelector,
} from '../../../features/HallSchema/selectors';
import { moveBookingSelectors } from 'features/MoveBooking';
import { constant } from 'lodash';
import { useGetTableAction, useIsEndingSoon } from '../Table/hooks';
import { getStatusColor } from './utils';
import styles from './style.module.scss';
import { BottomBlock } from './BottomBlock/BottomBlock';
import { isManagerialTable } from '../../../utils';
import { TableNumber } from './TableNumber/TableNumber';
import { clientShortName } from 'common/helpers';
import type { BookingTable } from 'types/table';
import { useDragNDropTables } from './useDragNDropTables';
import { timelineSelectors } from 'features/Timeline';

export type TBookingTableTemplateProps = {
  onClick?: () => void;
  tableSlots: TTableSlots[number];
  table: BookingTable;
};

export const BookingTableTemplate = ({
  table: { tableId, tableNumber, tableCapacity, figure, position },
  tableSlots,
}: TBookingTableTemplateProps) => {
  if (!tableId) return null;
  const hallMode = useSelector(hallModeSelector);
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const activeTables = useSelector(activeTablesSelector);
  const moveSource = useSelector(moveBookingSelectors.sourceBookingId);
  const timeWithDate = useSliderDatetime();
  const slot = selectSlotByShift(tableSlots, timeWithDate());
  const { drag, dragPreview, drop, isOver, isDragging } = useDragNDropTables(
    slot,
    tableId,
    tableNumber,
    figure
  );
  const {
    tableColor,
    timeWord,
    timeString,
    tableStatus,
    slot: { booking } = {},
  } = getSlotExtraOptions(slot, timeWithDate());
  const fullName = clientShortName(booking?.client || booking?.contact);
  const modalType = getType(hallMode, booking?.bookingId);

  const { handleTableClick, selectedTableId } = useGetTableAction({
    tableId,
    tableNumber,
    booking,
  });
  const isEndingSoon = useIsEndingSoon({ booking, tableColor });

  const isTableSelected = useMemo(
    () => moveBookingSelectors.isTableSelectedFactory(tableId),
    [tableId]
  );

  const isBookingSelected = useMemo(
    () =>
      booking
        ? moveBookingSelectors.isBookingSelectedFactory(booking.bookingId)
        : constant(false),
    [booking]
  );

  const isTableMoveTarget = useSelector(isTableSelected);
  const isBookingMoveTarget = useSelector(isBookingSelected);
  const isMoveTarget = useMemo(
    () => isTableMoveTarget || isBookingMoveTarget,
    [isTableMoveTarget, isBookingMoveTarget]
  );

  const moveBookingClass = useMemo(() => {
    if (moveSource && moveSource === booking?.bookingId)
      return styles.moveSource;
    if (isMoveTarget) return styles.moveTarget;
    return undefined;
  }, [moveSource, isMoveTarget, booking]);

  const isShowBorder = booking && booking.places.length > 1;

  const statusColor
    = (booking && getStatusColor(booking, isActualTime, tableColor))
    || 'var(--floorplanTable_empty_background)';

  const alerts = {
    isOverbooking: tableSlots?.some(({ booking: b }) => b?.isOverbooking),
    isVip: Boolean(slot?.booking?.client?.vip),
    isDeposit: Boolean(booking?.useDeposit),
    isEndingSoon: isEndingSoon,
    isManagerial: Boolean(booking && isManagerialTable(booking)),
  };

  const hasAlerts = Object.values(alerts).some(Boolean)

  const isSelectedOrTargetTable
    = (modalType === 'select-table'
      && !!activeTables.find((it) => it === tableId))
    || selectedTableId === tableId
    || isOver;

  return figure && position ? (
    <g ref={drop} className={`table-${tableNumber}`}>
      <foreignObject
        x={position.x}
        y={position.y}
        width={figure.width + 32}
        height={figure.height + 32 + Number(fullName && 35) + Number(hasAlerts && 5)}
        className={cn({
          [styles.selectedWrapper]: isSelectedOrTargetTable || isDragging,
        })}
      >
        {/* Дополнительная обертка, чтобы избежать багов визуализации в Safari на ios */}
        <div
          className={cn(
            styles.tableGroup,
            { [styles.selectedTable]: isSelectedOrTargetTable },
            moveBookingClass
          )}
        >
          {/* Обводка если столы объеденины */}
          <article
            className={cn(styles[figure.shape], styles.borderWrapper)}
            style={{
              height: figure.height + 17 + Number(fullName && 35) + Number(hasAlerts && 5),
              width: figure.width + 17,
              background: 'transparent',
            }}
          >
            <div
              ref={drag}
              className={cn(styles.tableWrapper, styles[figure.shape], {
                [styles.border]:
                  isShowBorder
                  && !(moveBookingClass || isSelectedOrTargetTable),
              })}
              style={{
                background: booking?.extraStatus
                  ? `linear-gradient(to right top, ${statusColor} 50%, ${booking.extraStatus.color} 50%)`
                  : statusColor,
                outlineColor: statusColor,
                height: figure.height,
                width: figure.width,
              }}
            >
              <div className={styles.tableBody}>
                <div
                  data-no-d3-zoom
                  className={cn(styles.tableContainer)}
                  onClick={handleTableClick}
                >
                  <div data-no-d3-zoom className={styles.tableInfo}>
                    <ICONS.GuestsIcon data-no-d3-zoom width={20} height={20} />
                    <span data-no-d3-zoom>
                      {tableStatus ? booking?.persons : tableCapacity}
                    </span>
                  </div>
                  <div data-no-d3-zoom className={styles.bookingInfo}>
                    {Boolean(timeString) && (
                      <span data-no-d3-zoom>{timeString}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
              <TableNumber
              statusColor={statusColor}
              isBookingSoon={timeWord === 'BOOKING_SOON'}
              tableNumber={tableNumber}
            />
            {/* Уведомления */}
            {hasAlerts && (
              <BottomBlock
                style={{ marginTop: figure.height }}
                alerts={alerts}
              />
            )}

            {fullName && (
              <div
                ref={dragPreview}
                className={cn(styles.fullName, {
                  [styles.selectedFullName]:
                    isSelectedOrTargetTable || isDragging,
                })}
              >
                <p>{fullName}</p>
              </div>
            )}
          </article>
        </div>
      </foreignObject>
    </g>
  ) : (
    <TemplatePlaceholder />
  );
};
