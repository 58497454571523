import {
  separateStatuses,
  getOptions,
  correctExtraStatusesFilter,
  getIncludedStatuses,
  filterBookingsListData,
  filterLocalStates,
} from './utils';
import {
  getActiveStatuses,
  getCompletedStatuses,
  useFetchAllStatusQuery,
} from 'features/api/dictionaries-api';
import { useEffect, useMemo, useState } from 'react';
import type { Locales } from 'types/commons';
import { useLocalStorage, useUpdateEffect } from 'react-use';
import { useBookingsList } from 'features/api/bookings-api';
import type { TUseBookingList } from 'types/booking';
import type {
  BookingModeType,
  BookingsData,
  SelectCheckboxOption,
} from './types';
import type { OptionSelectType } from 'ui-kit';
import type { OptionsType } from 'react-select';
import type { BaseStatus } from 'types/status';

export const useBookingFilters = (
  bookingMode: BookingModeType,
  locale: Locales,
  setBookingsData: React.Dispatch<React.SetStateAction<BookingsData>>,
  isManagerableTableSelected: boolean | undefined
) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: allStatuses } = useFetchAllStatusQuery();

  const { statuses, extraStatuses, waitListStatus } = separateStatuses(
    bookingMode === 'active'
      ? getActiveStatuses(allStatuses)
      : getCompletedStatuses(allStatuses)
  );

  const defaultStatusFilter = useMemo(
    () =>
      bookingMode === 'active'
        ? getOptions(statuses.filter((s) => s.system_name !== 'IN_HALL'))
        : getOptions(statuses.filter((s) => s.category === 'TERMINAL')),
    [statuses, bookingMode]
  );

  // фильтры, стартуем со всех выбранных

  const [statusFilter, setStatusFilter] = useLocalStorage<
    SelectCheckboxOption[] | null
  >(`dashboard:filter:statuses:${bookingMode}:${locale}`, defaultStatusFilter);

  const [extraStatusFilter, setExtraStatusFilter] = useLocalStorage<
    SelectCheckboxOption[] | null
  >(`dashboard:filter:extraStatuses:${bookingMode}:${locale}`, null);

  // filters extraStatusFilter from localStorage to remove disabled extra statuses.
  useEffect(() => {
    extraStatusFilter
      && setExtraStatusFilter(
        correctExtraStatusesFilter(extraStatuses, extraStatusFilter)
      );
  }, []);

  const includedStatuses = useMemo(
    () => getIncludedStatuses(statuses, statusFilter, waitListStatus),
    [statuses, statusFilter]
  );

  const bookingPayload: TUseBookingList = {
    search: searchQuery,
    includeStatuses: includedStatuses,
    isManagerableTableSelected,
  };

  const { data, isFetching, isError } = useBookingsList(bookingPayload);

  const { bookings, statistics, waitList } = filterBookingsListData(
    data,
    extraStatusFilter
  );

  useEffect(() => {
    setBookingsData({
      bookings,
      statistics,
      waitList,
      isLoading: isFetching || isError,
      includedStatuses,
      searchQuery,
    });
  }, [extraStatusFilter, data]);

  useUpdateEffect(() => {
    const isLocalStatusFilters = localStorage.getItem(
      `dashboard:filter:statuses:${bookingMode}:${locale}`
    );
    if (isLocalStatusFilters) return;
    setStatusFilter(defaultStatusFilter);
  }, [defaultStatusFilter]);

  const statusOptions = getOptions(statuses);
  const extraStatusOptions = getOptions(extraStatuses, statusFilter);

  useEffect(() => {
     let localStates;
     try {
       localStates = JSON.parse(
         localStorage.getItem(
           `dashboard:filter:statuses:${bookingMode}:${locale}`
         ) || 'null'
       );
     } catch {
       console.error('Не удалось прочитать сохраненные фильтры');
     }
    const statesArray: SelectCheckboxOption[] = Array.isArray(localStates)
      ? localStates
      : [];

    const filteredStates = filterLocalStates(
      statesArray,
      statuses,
      bookingMode
    );

    setStatusFilter(filteredStates.length ? filteredStates : statusOptions);
  }, [bookingMode]);

  const handleStatusFilterSelect = (
    options: OptionSelectType | OptionsType<OptionSelectType> | null
  ) => {
    setStatusFilter(options as SelectCheckboxOption[] | null);
    const newExtraStatusFulter = extraStatusFilter?.filter(
      (extraStatusOption) =>
        (options as SelectCheckboxOption[] | null)?.some(
          (stateOption) => stateOption.category === extraStatusOption.category
        )
    );
    newExtraStatusFulter && setExtraStatusFilter(newExtraStatusFulter);
  };

  const handleExtraStatusFilterSelect = (
    options: OptionSelectType | OptionsType<OptionSelectType> | null
  ) => {
    setExtraStatusFilter(options as SelectCheckboxOption[] | null);
  };

  return {
    isLoading: isFetching,
    searchQuery,
    setSearchQuery,
    handleStatusFilterSelect,
    handleExtraStatusFilterSelect,
    statusFilter,
    extraStatusFilter,
    statusOptions,
    extraStatusOptions,
  };
};
