import { useCallback, useState } from 'react';
import { Notification } from 'services/notification';
import { ResponseWithData } from '../../../models/common';
import { invalidateHallSlots } from '../hallschema-api';

import { coreApi } from './core';
import { ETranslations } from '../../../types/translates';
import type { Status } from 'types/status';

const bookingStatusApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getBookingStatus: build.query({
      query: (bookingId: number | string) => ({
        url: `/v2/status/nextStatus/${bookingId}`,
        method: 'get',
      }),
      transformResponse: (response: ResponseWithData<Status[]>) => {
        const terminal = response.data.find((s) => s.is_terminal);
        const next = response.data.find((s) => !s.is_terminal);
        return {
          terminal,
          next,
        };
      },
      providesTags: (_response, _error, bookingId) => [
        { type: 'BookingStatus', id: bookingId },
      ],
    }),
    changeBookingStatus: build.mutation<
      { error?: { status: number } },
      { bookingId: number; isTerminal?: boolean; force?: boolean }
    >({
      query: ({ bookingId, isTerminal = false, force }) => ({
        url: `/v2/booking/changeStatus/${bookingId}`,
        method: 'post',
        params: {
          force,
        },
        body: {
          is_terminal: isTerminal,
        },
      }),
      invalidatesTags: (_res, _err, { bookingId }) => [
        { type: 'BookingStatus', id: bookingId },
        { type: 'BookingExtraStatus', id: bookingId },
        { type: 'BookingHistory', id: bookingId },
        'Bookings',
        { type: 'Booking', id: bookingId },
        'TableOptions',
        'GlobalSearchBookings',
      ],
      transformResponse: (data: {
        errorCode?: number;
        errorMessage?: string;
      }) => {
        const { errorCode, errorMessage: message } = data || {};
        if (!errorCode) return { success: true };
        return {
          error: {
            status: errorCode,
            message,
          },
        };
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateHallSlots());
        } catch (e) {
          Notification.error({
            title: ETranslations.ERROR_UNABLE_TO_CHANGE_BOOKING_STATUS,
            message: e.error?.data?.errorMessage,
          });
        }
      },
    }),
    cancelBooking: build.mutation({
      query: (bookingId: number) => ({
        url: `/v2/booking/cancelBooking/${bookingId}`,
        method: 'post',
      }),
      invalidatesTags: (_res, _err, bookingId) => [
        { type: 'BookingStatus', id: bookingId },
        { type: 'BookingHistory', id: bookingId },
        'Bookings',
        { type: 'Booking', id: bookingId },
        'TableOptions',
        'GlobalSearchBookings',
      ],
      async onQueryStarted(bookingId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateHallSlots());
        } catch {
          Notification.error({
            title: ETranslations.ERROR_UNABLE_TO_CANCEL_BOOKING,
          });
        }
      },
    }),
    notComeBooking: build.mutation({
      query: (bookingId: number) => ({
        url: `/v2/booking/notCome/${bookingId}`,
        method: 'post',
      }),
      invalidatesTags: (_res, _err, bookingId) => [
        { type: 'BookingStatus', id: bookingId },
        { type: 'BookingHistory', id: bookingId },
        'Bookings',
        { type: 'Booking', id: bookingId },
        'TableOptions',
        'GlobalSearchBookings',
      ],
      async onQueryStarted(bookingId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateHallSlots());
        } catch {
          Notification.error({
            title: ETranslations.ERROR_UNABLE_TO_CANCEL_BOOKING,
          });
        }
      },
    }),
    setStatus: build.mutation({
      query: ({
        bookingId,
        statusSystemName,
      }: {
        bookingId: number;
        statusSystemName: string;
      }) => ({
        url: `/v2/status/applyStatus/${bookingId}`,
        method: 'post',
        params: {
          statusSystemName,
        },
      }),
      invalidatesTags: (_res, _err, { bookingId }) => [
        { type: 'BookingStatus', id: bookingId },
        'Bookings',
        { type: 'Booking', id: bookingId },
        { type: 'BookingHistory', id: bookingId },
        'TableOptions',
        'GlobalSearchBookings',
      ],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateHallSlots());
        } catch {
          Notification.error({
            title: ETranslations.ERROR_UNABLE_TO_CHANGE_BOOKING_STATUS,
          });
        }
      },
    }),
    cleanExtraStatus: build.mutation({
      query: (bookingId: number) => ({
        url: `v2/status/cleanExtra/${bookingId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, err, bookingId) => [
        'Bookings',
        'GlobalSearchBookings',
        { type: 'Booking', id: bookingId },
        { type: 'BookingHistory', id: bookingId },
      ],
      async onQueryStarted(args, { queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            /* if (data === 'ERROR') throw data; */
          })
          .catch((e) => {
            if (e.error.data.errorCode === 10000) {
              return Notification.error({
                title: e.error.data.errorMessage,
              });
            }

            if (e.status === 'ERROR') {
              return Notification.error({
                title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                message: e.error?.message,
              });
            }
            return Notification.error({
              title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
              message: e.error?.message,
            });
          });
      },
    }),
  }),
});

export const {
  useGetBookingStatusQuery,
  useNotComeBookingMutation,
  useCancelBookingMutation,
  useSetStatusMutation,
  useCleanExtraStatusMutation,
} = bookingStatusApi;

export function useChangeBookingStatus(bookingId: number | null | undefined) {
  const [changeStatus] = bookingStatusApi.useChangeBookingStatusMutation();
  const [force, setForce] = useState<boolean | undefined>();

  const resetForce = useCallback(() => setForce(undefined), []);

  const nextStatus = useCallback(async () => {
    resetForce();
    if (!bookingId) return;
    // @ts-ignore
    const { data } = await changeStatus({ bookingId, force });
    if (data.error?.status === 10200) {
      setForce(true);
      throw data;
    }
  }, [bookingId, force, setForce]);

  const terminalStatus = useCallback(async () => {
    resetForce();
    if (!bookingId) return;
    // @ts-ignore
    const { data } = await changeStatus({ bookingId, isTerminal: true, force });
    if (data.error?.status === 10200) {
      setForce(true);
      throw data;
    }
  }, [bookingId, force, setForce]);

  return {
    force,
    resetForce,
    nextStatus,
    terminalStatus,
  };
}
