import {createSelector} from '@reduxjs/toolkit';
import {Restaurant, RestaurantExtended} from '../../models/restaurant.model';
import {dictionariesApi} from '../api/dictionaries-api';
import {Source} from '../../models/booking.model';
import _ from 'lodash';
import {SingleOption} from '../../models/common';
import {Client} from '../../models/client.model';
import {initialState, applicationContextSliceName, State} from './slice';
import moment from 'moment';

const restsMap = (input: Restaurant[]): RestaurantExtended[] => input.map((rest) => ({
  ...rest,
  label: rest.restaurant_name,
  value: rest.restaurant_id,
}));

const selectDomain = (state: any): State => state[applicationContextSliceName] || initialState;

const selectDomainForRestaurants = (state: any): Restaurant[] => state[dictionariesApi.reducerPath]
  .queries['fetchAllRestaurants({})'].data || [];
const selectDomainForSources = (state: any): Source[] => state[dictionariesApi.reducerPath]
  .queries['fetchAllSources({})'].data || [];
const selectDomainForDefaultClient = (state: any): Client | null => state[dictionariesApi.reducerPath]
  .queries['fetchDefaultUser({})']?.data || null;

// restaurants selectors
export const allRestaurantSelector = createSelector(selectDomainForRestaurants, (restaurants) => restsMap(restaurants));
export const restaurantByIdSelector = createSelector(
  [selectDomainForRestaurants, (__, restId: number) => restId],
  (restaurants, restId) => restsMap(
    restaurants.filter((it) => it.restaurant_id === restId),
  ),
);
export const placeByRestSelector = createSelector(
  [selectDomainForRestaurants, (__, restId: number) => restId],
  (restaurants, restId) => restaurants
      .filter((it) => it.restaurant_id === restId)
      .map((it) => it.places)
      .flat()
      .map((it) => ({...it, value: it.id, label: it.name})),
);

export const sourcesSelector = createSelector(
  selectDomainForSources,
  (sources) => _.sortBy(sources, 'source_name'),
);


export const defaultUserSelector = createSelector(selectDomainForDefaultClient, (client) => client);
export const date = createSelector(selectDomain, (state) => moment(state.date).clone());
export const selectedPlaces = createSelector(selectDomain, (state) => state.selectedPlaces);
export const restaurant = createSelector(selectDomain, (state) => state.restaurant);
export const place = createSelector([selectDomain, restaurant], (state, rest) => state.place ?? rest.places[0].id);
export const restaurants = createSelector(selectDomain, (state) => state.restaurants);
export const allPlaces = createSelector(restaurant, (rest) => rest.places);
export const placesOptions = createSelector(restaurant, (rest) => [...rest.places]
  .sort((a,b) => a.weight - b.weight  )
  .map((p) => new SingleOption(p.id, p.name)));
export const fullPlaces = createSelector(restaurants, (rests) => rests.flatMap((r) => r.places));
export const isDateChanged = createSelector(date, (curentDate) => curentDate.isSame(moment(), 'day'));
