import {
  OptionAppointRole,
  OptionSettingsMode,
  SettingsModes,
} from 'models/settings.model';
import { Booking } from 'models/booking.model';
import { M, predata } from 'common/helpers';
import { Role } from 'services/auth.service';
import { AppointUser } from 'models/user.model';
import { SelectModel } from 'models/common';
import { ETranslations } from '../../types/translates';
import { config } from 'config';
import { Shifts } from 'components/shifts';
import { RestaurantManagement } from 'components/RestaurantManagement';
import { BookingsHistory } from 'components/BookingsHistory';
import AccessRules from './sub-renders/access_rules';
import AppointRoles from './sub-renders/appoint-roles';
import type { Settings } from './settings';
import AppointHostess from './sub-renders/appoint-hostess';
import { StatusManagement } from './sub-renders/status-management/status-management';
import TagsPage from './sub-renders/tags';

/* eslint-disable */
export const getOptionsSettingsMode = (): OptionSettingsMode[] =>
  [
    {
      value: 6,
      label: ETranslations.SETTINGS_TITLE_SHIFTS,
      mode: SettingsModes.shifts,
      permissions: ['ADMINISTRATOR', 'ADMINISTRATOR_RESTAURANT'],
    },
    {
      value: 1,
      label: ETranslations.SETTINGS_TITLE_ROLES,
      mode: SettingsModes.appointRoles,
      permissions: ['ADMINISTRATOR'],
    },
    {
      value: 2,
      label: ETranslations.SETTINGS_TITLE_HOSTESS,
      mode: SettingsModes.appointHostess,
      permissions: ['ADMINISTRATOR', 'ADMINISTRATOR_RESTAURANT'],
    },
    // {value: 3, label: 'Источники', mode: SettingsModes.sources},
    // {value: 4, label: 'Заведение нового ресторана', mode: SettingsModes.newRest},
    {
      value: 5,
      label: ETranslations.SETTINGS_TITLE_BOOKING_HISTORY,
      mode: SettingsModes.changeBook,
      permissions: [
        'ADMINISTRATOR_RESTAURANT',
        'ADMINISTRATOR',
        'HOSTESS',
        'RESTAURANT',
      ],
    },
    {
      value: 6,
      label: ETranslations.SETTINGS_TITLE_RESTAURANT_MANAGEMENT,
      mode: SettingsModes.restManagement,
      permissions: ['ADMINISTRATOR_RESTAURANT', 'ADMINISTRATOR'],
    },
    {
      value: 7,
      label: ETranslations.ACCESS_RULES,
      mode: SettingsModes.access_rules,
      permissions: ['ADMINISTRATOR'],
    },
    {
      value: 8,
      label: ETranslations.STATUSES,
      mode: SettingsModes.statuses,
      permissions: ['ADMINISTRATOR'],
    },
    {
      value: 9,
      label: ETranslations.TAGS,
      mode: SettingsModes.tags,
      permissions: [
        'ADMINISTRATOR_RESTAURANT',
        'ADMINISTRATOR',
        'HOSTESS',
        'RESTAURANT',
      ],
    },
  ].filter(Boolean) as OptionSettingsMode[];

export const appointRolesList: OptionAppointRole[] = [
  {
    id: 1,
    label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN,
    role: 'ADMINISTRATOR',
    declension: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN_DECL,
    data: [],
  },
  {
    id: 2,
    label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN,
    role: 'ADMINISTRATOR_RESTAURANT',
    declension: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN_DECL,
    data: [],
  },
  {
    id: 3,
    label: ETranslations.SETTINGS_ROLE_HOSTESS,
    role: 'HOSTESS',
    declension: ETranslations.SETTINGS_ROLE_HOSTESS_DECL,
    data: [],
  },
  {
    id: 4,
    label: ETranslations.SETTINGS_ROLE_CALL_CENTER,
    role: 'CALL_CENTER',
    declension: ETranslations.SETTINGS_ROLE_CALL_CENTER_DECL,
    data: [],
  },
  {
    id: 5,
    label: ETranslations.SETTINGS_ROLE_RESTAURANTS,
    role: 'RESTAURANT',
    declension: ETranslations.SETTINGS_ROLE_RESTAURANTS_DECL,
    data: [],
  },
];

export const optionsAppointRoles: SelectModel[] = [
  { value: 'ADMINISTRATOR', label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN },
  {
    value: 'ADMINISTRATOR_RESTAURANT',
    label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN,
  },
  { value: 'HOSTESS', label: ETranslations.SETTINGS_ROLE_HOSTESS },
  { value: 'CALL_CENTER', label: ETranslations.SETTINGS_ROLE_CALL_CENTER },
  { value: 'RESTAURANT', label: ETranslations.SETTINGS_ROLE_RESTAURANTS },
];
/* eslint-enable */

export const isBookingsDateEqual = (
  prevBook: Booking | undefined,
  { date }: Booking
) => {
  if (!prevBook) {
    return false;
  }
  return M(prevBook.date).isSame(date, 'd');
};

export const getOptionAppointRole = (
  desiredRole?: Role
): SelectModel | undefined => {
  const foundOption = optionsAppointRoles.find(
    ({ value }) => value === desiredRole
  );
  return foundOption;
};

export const getRestName = ({ restaurant_id: id, role, name }: AppointUser) => {
  if (typeof id !== 'number' && role === 'ADMINISTRATOR') {
    return ETranslations.ALL_RESTAURANTS;
  }
  if (typeof id !== 'number') {
    return ETranslations.UNKNOWN_RESTAURANT;
  }
  if (role === 'RESTAURANT') {
    return name;
  }
  return predata.restsMapper[id]?.label;
};

export const renderCardContent = (settings: Settings) => {
  const {
    props: {
      onCallModalHostess,
      onCallModalConfirm,
      onCallModalUser,
      onCallModalStatus,
      intlUtils,
    },
    state: {
      selectedMode,
      restaurantList,
      isLoading,
      selectedSectionId,
      endDate,
      clientName,
      userName,
      hostess,
    },
  } = settings;
  switch (selectedMode.mode) {
    case SettingsModes.sources:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.SOURCES,
          })}
        </div>
      );
    case SettingsModes.newRest:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.STARTING_NEW_RESTAURANT,
          })}
        </div>
      );
    case SettingsModes.appointHostess:
      return (
        <AppointHostess
          isLoading={isLoading}
          restaurantList={restaurantList}
          selectedSectionId={selectedSectionId}
          onCallModalHostess={(hostessUser, restId) =>
            onCallModalHostess(hostessUser, restId)
          }
          onCallModalConfirm={(id, mode) => onCallModalConfirm(id, mode)}
          setRestaurant={(restId) => settings.setRestaurant(restId)}
        />
      );
    case SettingsModes.appointRoles:
      return (
        <AppointRoles
          isLoading={isLoading}
          rolesList={settings.filteredList}
          selectedSectionId={selectedSectionId}
          onCallModalUser={(user, role) => onCallModalUser(user, role)}
          onCallModalConfirm={(id, mode) => onCallModalConfirm(id, mode)}
          setActiveRole={(optionRole) => settings.setActiveRole(optionRole)}
          sectionDataDeclension={settings.sectionDataDeclension}
          userName={userName}
        />
      );
    case SettingsModes.access_rules:
      return <AccessRules isLoading={isLoading} />;
    case SettingsModes.tags:
      return <TagsPage />;
    case SettingsModes.changeBook:
      return (
        <BookingsHistory
          dateTo={endDate}
          dateFrom={endDate}
          searchWords={clientName}
          userid={hostess?.value}
        />
      );
    case SettingsModes.shifts:
      return <Shifts />;
    case SettingsModes.restManagement:
      return <RestaurantManagement />;
    case SettingsModes.statuses:
      return (
        <StatusManagement
          onCallModalStatus={(allStatuses, statusId, editStatus, isEditing) =>
            onCallModalStatus(allStatuses, statusId, editStatus, isEditing)
          }
        />
      );
    default:
      return null;
  }
};
