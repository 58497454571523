import {predata} from 'common/helpers';
import {IResponse} from 'models/common';
import {Place, Restaurant} from 'models/restaurant.model';
import {useMemo} from 'react';
import {UpdatePlaceDTO} from 'types/place';
import {api} from './api';

export const restaurantsApi = api
  .enhanceEndpoints({
    addTagTypes: ['Restaurants'],
  })
  .injectEndpoints({
    endpoints: ($) => ({
      fetchAllRestaurants: $.query<Restaurant[], void>({
        query: () => ({
          url: 'v2/restaurants',
        }),
        providesTags: ['Restaurants'],
        transformResponse: (response: IResponse<Restaurant[]>) =>
          response.data.map((rest) => ({
            ...rest,
            places: rest.places.sort((a, b) => a.weight - b.weight),
          })),
        async onQueryStarted(args, {queryFulfilled}) {
          try {
            const {data} = await queryFulfilled;
            data.forEach((rest) => {
              predata.restsMapper[rest.restaurant_id] = {
                label: rest.restaurant_name,
                value: rest.restaurant_id,
                ...rest,
              };
            });
          } catch (e) {
            e?.error?.data?.status === 500 
            && global.channels.auth.cb?.(false); // временная мера, убрать как только бэк станет возвращать 401 при инвалидном токене
          }
        },
      }),
      updateHall: $.mutation<Place, Partial<UpdatePlaceDTO>>({
        query: (body) => ({
          url: 'v2/places',
          method: 'POST',
          body: {
            ...body,
            schedule: body.schedule ?? [
              {
                duration: 10,
                booking_interval: 10,
                time_start: '12:00:00',
                week_day: 1,
              },
            ],
          },
        }),
        invalidatesTags: (_res, err) => (!err ? ['Restaurants'] : []),
        transformResponse: (response: IResponse<Place>) => response.data,
      }),
      deleteHall: $.mutation({
        query: (id: number | string) => ({
          url: `v2/places/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Restaurants'],
      }),
    }),
  });

export const {
  useFetchAllRestaurantsQuery,
  useUpdateHallMutation,
  useDeleteHallMutation,
} = restaurantsApi;

export function useRestsOptions() {
  // const restaurants = {data: [] as Restaurant[]};
  const {data: restaurants} = useFetchAllRestaurantsQuery();

  return useMemo(
    () =>
      restaurants?.map((rest) => ({
        value: rest.restaurant_id,
        label: rest.restaurant_name,
      })),
    [restaurants]
  );
}
