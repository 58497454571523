import { Dashboard } from 'containers/Dashboard';
import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { api } from './api';
import { IResponse } from 'models/common';
import {
  EditShiftStatusPayload,
  FetchShiftResponse,
  FetchShiftsListResponse,
  HourData,
  MinuteData,
  Shift,
  ShiftsCalendarWeekPayload,
  ShiftsRulesPayload,
  WeekShift,
} from 'types/shift';
import { appContextSelectors } from 'features/AppContex';
import { ShiftRule, VisitTime } from 'types/shifts';
import { Notification } from '../../services/notification';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useHistory } from 'react-router';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isSameOrAfter);

export const shiftsApi = api
  .enhanceEndpoints({
    addTagTypes: ['shift', 'rules'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchWeekShifts: build.query<WeekShift[], ShiftsCalendarWeekPayload>({
        query: (params) => ({
          url: '/shifts/calendar/week',
          method: 'GET',
          params,
        }),
        transformResponse: (response: IResponse<WeekShift[]>) => response.data,
        providesTags: ['Shifts'],
      }),
      fetchShiftsRules: build.query<ShiftRule[], ShiftsRulesPayload>({
        query: (params) => ({
          url: '/shifts/rules',
          params,
        }),
        transformResponse: (response: IResponse<ShiftRule[]>) => response.data,
        providesTags: ['rules'],
        async onQueryStarted(id, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
        keepUnusedDataFor: 0,
      }),
      fetchShift: build.query<Shift, number>({
        query: (id) => ({
          url: `/shifts/${id}`,
          method: 'GET',
        }),
        providesTags: ['shift'],
        transformResponse: (response: FetchShiftResponse) => response.data,
      }),
      createShift: build.mutation<FetchShiftResponse, Partial<Shift>>({
        query: (payload: Shift) => ({
          url: '/shifts/create',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Shifts', 'Timeline', 'rules'],
        async onQueryStarted(id, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
      }),
      editShift: build.mutation({
        query: (payload: { id: number; data: Partial<Shift> }) => ({
          url: `/shifts/${payload.id}`,
          method: 'PUT',
          body: payload.data,
        }),
        invalidatesTags: ['Shifts', 'shift', 'Timeline', 'rules'],
        async onQueryStarted(id, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
      }),
      deleteShift: build.mutation({
        query: (id: number) => ({
          url: `/shifts/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Shifts'],
      }),
      editShiftStatus: build.mutation({
        query: (payload: EditShiftStatusPayload) => ({
          url: `/shifts/active/${payload.id}`,
          method: 'PUT',
          params: {
            active: payload.active,
          },
        }),
      }),
      fetchShiftsList: build.query<FetchShiftsListResponse, number>({
        query: (restaurantId: number) => ({
          url: `/shifts/list/${restaurantId}`,
          method: 'GET',
        }),
        providesTags: ['Shifts'],
      }),
    }),
  });

export const {
  useFetchWeekShiftsQuery,
  useFetchShiftQuery,
  useLazyFetchShiftQuery,
  useFetchShiftsRulesQuery,
  useCreateShiftMutation,
  useEditShiftMutation,
  useDeleteShiftMutation,
  useEditShiftStatusMutation,
  useFetchShiftsListQuery,
} = shiftsApi;

const DEFAULT_RULES: ShiftRule[] = [];

export function useShiftRules() {
  const place_id = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const { restaurant_id } = useSelector(appContextSelectors.restaurant);

  const {
    data = DEFAULT_RULES,
    error,
    ...rest
  } = useFetchShiftsRulesQuery({
    place_id,
    restaurant_id,
    date: date.format('YYYY-MM-DD'),
  });

  return {
    ...rest,
    rules: !error ? data : [],
  };
}

const intervalSlicer: Record<number, number> = {
  5: 6,
  10: 3,
  15: 2,
  30: 1,
};

export function useShiftTimes() {
  const { rules, isFetching } = useShiftRules();
  const restaurant = useSelector(appContextSelectors.restaurant);

  const visitTimes = useMemo(() => {
    let times: VisitTime = {};
    rules.forEach((rule) => {
      const interval = rule?.time_interval || 0;
      const copy = { ...rule.absolute_times };
      const keys = Object.keys(copy).splice(-intervalSlicer[interval]);
      //Убираем последние два слота. Так как там время 0 и 15минут. Минимальное время брони 30.
      keys.forEach((key) => delete copy[key]);
      times = Object.assign(times, copy);
    });

    const formatedTimes: VisitTime = {};
    Object.entries(times).forEach(([timestamp, availableMinutes]) => {
      //@ts-ignore
      const transformed = dayjs(+timestamp)
        .utc()
        .tz(restaurant.timezone, true)
        .valueOf();

      formatedTimes[transformed] = availableMinutes;
    });

    return formatedTimes;
  }, [rules]);

  return { visitTimes, isFetching };
}

export function useShiftTimesOptions(allowValueFromOutsideRange = false) {
  // todo: clean code here
  const now = useMemo(dayjs, []);

  const { visitTimes, isFetching } = useShiftTimes();
  let timeOptions = Object.keys(visitTimes)
    .map(Number)
    .map((each) => {
      const timestamp = each;
      // @ts-ignore
      const gmtTime = dayjs(timestamp);
      return gmtTime;
    });
  // const lastTimestamp = timeOptions[timeOptions.length - 1];
  // const timeSeparator = dayjs(lastTimestamp).set('hour', -23);
  //
  // timeOptions = timeOptions.filter((time) => {
  //   return dayjs(time).isAfter(timeSeparator)
  // })

  if (!allowValueFromOutsideRange) {
    timeOptions = timeOptions.filter((time) =>
      time.isSameOrAfter(now, 'minute'));
  }

  const options = timeOptions.map((time) => ({
    value: time.valueOf(),
    label: time.format('HH:mm'),
  }));

  return { options, isFetching, timeOptions };
}

export const useReturnAllSlots = () => {
  const date = useSelector(appContextSelectors.date).valueOf();

  const currentDate = dayjs(date).startOf('day');
  const endTime = dayjs(date).endOf('day').subtract(10, 'minutes'); // 23:50

  const options = [];
  let currentTime = currentDate;

  while (currentTime.isBefore(endTime)) {
    options.push({
      value: currentTime.valueOf(),
      label: currentTime.format('HH:mm'),
    });
    currentTime = currentTime.add(15, 'minutes');
  }

  return { options };
};

export function useShiftHoursAndMinutes() {
  const { timeOptions } = useShiftTimesOptions(true);

  const transformedData = useMemo(() => {
    const organizedData: HourData[] = [];
    let currentHourObject: HourData | null = null;

    for (let i = 0; i < timeOptions.length; i++) {
      const timestamp = dayjs(timeOptions[i]);
      const hour = timestamp.format('HH');
      const minute = timestamp.format('mm');

      if (!currentHourObject || currentHourObject.label !== hour) {
        currentHourObject = {
          label: hour,
          value: timeOptions[i].valueOf(),
          minutes: [],
        };
        organizedData.push(currentHourObject);
      }

      const minuteData: MinuteData = {
        label: minute,
        value: timeOptions[i].valueOf(),
      };

      currentHourObject.minutes.push(minuteData);
    }

    return organizedData;
  }, [timeOptions]);

  return transformedData;
}

export function useActiveShift(time: Moment) {
  const { rules } = useShiftRules();
  return rules.find((rule) => {
    const start = moment(rule.low_bounder, 'HH:mm:ss');
    const end = moment(rule.high_bounder, 'HH:mm:ss');

    const ruleStart = time.clone().set({
      hour: start.hour(),
      minute: start.minute(),
      second: start.second(),
    });

    const ruleEnd = time
      .clone()
      .set({
        hour: end.hour(),
        minute: end.minute(),
        second: end.second(),
      })
      .add(end.isSameOrBefore(start) ? 1 : 0, 'days');

    return time.isSameOrAfter(ruleStart) && time.isSameOrBefore(ruleEnd);
  });
}
